import React from "react"
import Layout from "../components/layout"
import FAQSection from "../components/faq-section"
import {graphql} from "gatsby"
import HeaderH1 from "../components/headerH1";
import {Helmet} from "react-helmet-async";
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";

export default function Faq({data}) {
  const files = data.allMarkdownRemark?.edges
  const faqArticles = files?.map((element) => element.node.frontmatter)
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqArticles.flatMap((faqSection) => faqSection.faq).map((faqElement) => {
      return {
        "@type": "Question",
        "name": faqElement.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": faqElement.answer
        }
      }
    })
  }

  return (
    <>
      <GatsbySeo title="Frequently Asked Questions"
                 description="The most common questions about how LinkFive works and what we can do for you."
      />
      <Layout fullWave="false" bottomWave="false" callToAction="true">
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(jsonLd)}
          </script>
        </Helmet>
        <section className="bg-white py-20">
          <div className="container max-w-5xl mx-auto m-8">
            <HeaderH1 headerText="Frequently Asked Questions"/>
          </div>

          <div className="max-w-5xl mx-auto m-8 text-gray-500 text-xl text-center">
            <p className="text-center">
              The most common questions about how LinkFive works and what we can
              do for you.
            </p>

            <section className="text-gray-700 text-left">
              <div className="container px-5 mx-auto">
                <div className="flex flex-wrap sm:mx-auto sm:mb-2 -mx-2">
                  <div className="w-full px-4 py-2 my-8">
                    {
                      faqArticles.map((faqSection) => <FAQSection data={faqSection} key={faqSection.section}/>)
                    }
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  {
  allMarkdownRemark(
    limit: 1000,
    filter: {fileAbsolutePath: {regex: "/(content/faq)/" }},
    sort: { fields:frontmatter___index,  order:ASC}
  ) {
    edges {
      node {
        frontmatter {
          section 
          faq {
            question
            answer
          }
        }
      }
    }
  }
}
`