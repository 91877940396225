import React from "react"
import FAQDetails from "./faq-details";

export default function FAQSection(props) {
    return (
        <>
            <h2 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">{props.data.section}</h2>
            {
                props.data.faq.map((faqElement) => <FAQDetails data={faqElement} key={faqElement.question}/>)
            }
        </>
    )
}
